// main : stylesheet.scss
.button, .btn {

    padding: 10px 30px;
    color: #fff;
    text-align: center;
    //background: #fff;
    font-size: 16px;
    border: 0px;
    display: inline-block;
    font-weight: bold;
    border-radius: 30px;
    &.no-mt {
        margin-top: 0;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: #fff;
    }

    &.red{
        background: $red;
        color: #fff;

        &:hover{
            background: darken($red, 10%);
        }
    }

    &.newletter{
        img{
            margin: 0 auto;
        }
    }

    &.lightblue, &.btn-primary{
        background: $lightblue;
        color: #fff;

        &:hover{
            background: darken($lightblue, 10%);
        }
    }

    &.border-button{
        border: 2px solid $red;
        background: #fff;
        color: $red;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        .icon{
            margin-right: 10px;
            svg{
                display: block;
                path{
                    fill: $red!important;
                }
            }
        }
        &:hover{
            background: $lightblue;
            border: 2px solid $lightblue;

            &.active{
                border: 2px solid $red;
            }
            color: #fff;
            .icon{
                svg{
                    display: block;
                    path{
                        fill: #fff!important;
                    }
                }
            }
        }

        &.active{
            background: $red;
            color: #fff;
            .icon{
                svg{
                    display: block;
                    path{
                        fill: #fff!important;
                    }
                }
            }
        }
    }

    &.filter{
        border: 2px solid $lightblue;
        background: $lightblue;
        color: #fff;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        .icon{
            margin-right: 10px;
            svg{
                display: block;
                path{
                    fill: #fff!important;
                }
            }
        }
        &:hover{
            background: #fff;
            border: 2px solid $lightblue;


            color: $lightblue;
            .icon{
                svg{
                    display: block;
                    path{
                        fill: $lightblue!important;
                    }
                }
            }
        }

    }

}
