// main : stylesheet.scss
.main-banner{

    &.leasedlines{
        background-image: url('/image/leased.jpg');
        background-size: cover;
        background-position: 50% 50%;
        .inner{
            background-image: none!important;
            
            .text{

                @media(min-width: 1200px){
                    max-width: 550px;
                    flex-basis: 550px;
    
                }
               
              
                h2{
                    color: $lightblue;
                        font-size: 50px;
                        line-height: 1;
                        margin-bottom: 10px;
                        @media(max-width: 1199px){
                            font-size: 35px;
                        }
                        @media(max-width: 991px){
                            text-align: center;
                        }
                        @media(max-width: 575px){
                            font-size: 30px;
                        }
                }
    
                h1,p{
                    text-shadow: 1px 1px 5px rgba(0,0,0,0.4);
                }
                .sub{
                    p{
                        font-weight: 500;
                        font-size: 30px;
                    }
                }
                
            }
            
        }
    }

    background: #000;
    .stored{
      .inner{
        padding: 60px 0;
        @media (max-width: 991px){
          padding:40px 0;
          background-size: auto 100%;
        }
        .text{
          @media (min-width: 991px){
            padding-left: 300px;
            max-width: none;
            flex-basis: auto;
          }
        }
      }
    }
    .inner{
        padding: 60px 0;

        background-image: url('/image/dog.jpg');
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: auto 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media(max-width: 1199px){
            padding: 40px 0;
        }
        @media(max-width: 991px){
            background-size: auto 70%;
        }
        @media(max-width: 767px){
            background-image: none;
        }

        .inner-form{
            text-shadow: 0px 0px 5px black;
        }


        .text{
            max-width: 600px;
            flex-basis: 600px;

            @media(max-width: 1199px){
                max-width: 400px;
                flex-basis: 400px;
            }
            @media(max-width: 991px){
                max-width: 100%;
                flex-basis: 100%;
            }
            @media(max-width: 991px){
                background-size: auto 50%;
            }

            .category-title{
                color: #fff;
                font-size: 20px;
                font-weight: 300;;
            }
            h2{
                color: $lightblue;
                font-size: 63px;
                line-height: 1;
                margin-bottom: 20px;
                @media(max-width: 1199px){
                    font-size: 40px;
                }
                @media(max-width: 991px){
                    text-align: center;
                }
                @media(max-width: 575px){
                    font-size: 35px;
                }
            }

            h1{
                &.home-title{
                    color: $lightblue;
                    font-size: 40px;
                    line-height: 1;
                    margin-bottom: 20px;
                    @media(max-width: 1199px){
                        font-size: 35px;
                    }
                    @media(max-width: 991px){
                        text-align: center;
                    }
                    @media(max-width: 575px){
                        font-size: 30px;
                    }
                }
            }
        
            p{
                color: #fff;
                font-size: 24px;
                font-weight: 300;
                line-height: 1.5;
                @media(max-width: 1199px){
                    font-size: 17px;
                }
                @media(max-width: 991px){
                    text-align: center;
                }
                @media(max-width: 575px){
                    font-size: 15px;
                }
            }
        }
    }

    .hexagon{
        position: relative;
        margin-left: auto;
        margin-right: 0;
        z-index: 5;
        margin: 30px auto;
        width: 117px;
        height: 200px;
        border-radius: 1em/.5em;
        background: #25aae1;

        transition: opacity .5s;
            &:before, &:after {
            position: absolute;
            z-index: 5;
            width: inherit; height: inherit;
            border-radius: inherit;
            background: inherit;
            content: '';
          }
          &:before {
             transform: rotate(60deg);
          }
          &:after {
            transform: rotate(-60deg);
          }

          .hex-inner{
              position: absolute;

              z-index: 10;
              padding: 15px 0;
              width: 200%;
              height: 100%;
              text-align: center;
              transform: translateX(-25%);
              span{
                  display: block;
                  font-size: 18px;
                    line-height: 1;
                    user-select: none;
                    color: #fff;
                    @media(max-width: 1199px){
                        font-size: 16px;
                    }

                  &.big{
                        font-size: 78px;

                        font-weight: bold;
                        @media(max-width: 1199px){
                            font-size: 50px;
                        }
                  }

                  &.text-only{
                        line-height: 1.3;
                        margin-top: 20px;
                        font-size: 25px;
                        @media(max-width: 1199px){
                            margin-top: 10px;
                        font-size: 20px;
                        }
                  }
              }

              .button{
                  padding: 4px 10px;
                  font-size: 13px;
                  font-weight: 400;
                  margin-top: 15px;
                  @media(max-width: 1199px){
                        font-size: 12px;
                        margin-top: 5px;
                    }

                    color: #fff;
                    &:hover, &:focus{
                        background: $red;
                    }
              }
          }

        @media(max-width: 1199px){
            width: 90px;
            height: 150px;
        }
        @media(max-width: 767px){
            display: none;
        }
    }

    &.small{
        .inner{
            padding: 60px 0;
            background-image: url('/image/dog.jpg');
            background-repeat: no-repeat;
            background-position: left bottom;
            background-size: auto 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @media(max-width: 1199px){
                padding: 40px 0;
            }
            @media(max-width: 991px){
                background-size: auto 70%;
                justify-content: flex-end;
            }
            @media(max-width: 767px){
                background-image: none;
                justify-content: flex-start;
            }
            .text{
                max-width: 500px;
                flex-basis: 500px;
                @media(max-width: 1199px){
                    max-width: 300px;
                    flex-basis: 300px;
                }
                @media(max-width: 767px){
                   max-width: 500px;
                    flex-basis: 500px;
                }
                @media(max-width: 991px){
                    max-width: 500px;
                    flex-basis: 500px;
                }
                h2{
                    color: $lightblue;
                    font-size: 50px;
                    line-height: 1;
                    margin-bottom: 15px;
                    @media(max-width: 1199px){
                        font-size: 35px;
                    }
                    @media(max-width: 991px){
                        text-align: center;
                    }

                }
                p{
                    color: #fff;
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 1.5;
                    @media(max-width: 1199px){
                        font-size: 18px;
                    }
                    @media(max-width: 991px){
                        text-align: center;
                    }
                    @media(max-width: 575px){
                        font-size: 16px;
                    }
                }
            }
        }

        .hexagon{
            margin: 30px auto;
            margin-right: 100px;

            @media(max-width: 991px){
                display: none;
            }
        }

        &.checkout{
            .inner{
                justify-content: flex-end;
                @media(max-width: 991px){
                    background-size: auto 90%;
                }
                .text{
                    max-width: 800px;
                    flex-basis: 800px;
                    @media(max-width: 1199px){
                        max-width: 600px;
                        flex-basis: 600px;
                    }

                    @media(max-width: 991px){
                        max-width: 500px;
                        flex-basis: 500px;
                    }

                    h2,h1{
                        font-size: 40px;
                        color: $lightblue;
                        @media(max-width: 1199px){
                            font-size: 35px;
                        }
                        @media(max-width: 991px){
                            text-align: left;
                            font-size: 30px;
                        }
                        @media(max-width: 767px){
                            text-align: center;
                        }

                    }
                    p{
                        margin: 0;
                        @media(max-width: 991px){
                            text-align: left;
                        }
                        @media(max-width: 767px){
                            text-align: center;
                        }
                    }

                }
            }
        }
    }
}
