.leased-lines-page {
  .section {
    &.black-with-bg{
        .section-description{
            padding-right: 200px;
            @media (max-width: 1199px) {
                padding-right: 100px;
            }
            @media (max-width: 991px) {
                padding-right: 0;
            }
        }

        .knowledge-button{
            background: $red;
            font-weight: 300;
            border-radius: 5px;;
            display: inline-block;
            padding: 5px 30px;
            
            color: #fff;
            margin: 100px 0;
            font-size: 22px;
            i{
                font-size: 30px;
                margin-left: 20px;
                @media (max-width: 991px) {
                    font-size: 26px;
                }
                @media (max-width: 767px) {
                    font-size: 22px;
                }
            }
            &:hover{
                background: $lightblue;
            }

            
            @media (max-width: 991px) {
                font-size: 20px;
            }
            @media (max-width: 767px) {
                font-size: 18px;
                padding: 5px 20px;
            }
        }
    }
    h3{
        font-size: 35px;
        font-weight: normal;
        &.white{
            color: #fff;
        }
        @media (max-width: 991px) {
            font-size: 30px;
        }
        @media (max-width: 767px) {
            font-size: 25px;
            line-height: 1.25;
        }
    }
    h4{
        font-size: 23px;
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }
    .section-description {
      font-size: 20px;
      font-weight: 300;
      @media (max-width: 991px) {
        font-size: 18px;
    }
    @media (max-width: 767px) {
        font-size: 16px;
    }
      ul {
          list-style: none;
          padding: 0;
        li {
          margin-bottom: 10px;
          padding-left: 25px;
          position: relative;
          &:before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 0;
            height: 15px;
            width: 15px;
            background-image: url("/image/circle1.svg");
            background-size: 15px 15px;
          }
        }
      }
    }
  }
}
