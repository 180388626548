#faqs{
  .faq-row{
    margin-bottom: 10px;
    .question{
      padding: 10px 20px;
      background: $lightblue;
      color: #fff;
      font-size: 23px;
      margin: 0;
      font-weight: 500;
      position: relative;
      padding-right: 70px;
      @media(max-width: 575px){
        padding-right: 40px;
      }
      cursor: pointer;
      &:before{
        content: '\f107';
        font-family: FontAwesome;
        background: $red;
        width: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top:0;
        @media(max-width: 575px){
          width: 30px;
        }
      }
      @media(max-width: 767px){
        font-size: 18px;
      }
      @media(max-width: 575px){
        font-size: 16px;
      }
    }
    .answer{
      background: #fff;
      padding: 20px;
      
      transform-origin: 0 0;
      display: none;
      @media(max-width: 575px){
        font-size: 14px;
      }
    }

    &:first-child{
      .answer{
        display: block;
      }
    }
  }
}
