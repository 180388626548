// main : stylesheet.scss
body {

    @media (min-width: 1220px) {
        .container {
            max-width: 1200px;
        }
    }

    font-family: 'Open Sans';
    color: #000;
    font-size: 15px;
    line-height: 1.4;

    .slide-enter-active {
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
     }

     .slide-leave-active {
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
     }

     .slide-enter-to, .slide-leave {
        max-height: 800px;
        overflow: hidden;
     }

     .slide-enter, .slide-leave-to {
        overflow: hidden;
        max-height: 0;
     }


    .slide-fade-enter-active {
        transition: .3s all .3s cubic-bezier(0.215, 0.610, 0.355, 1);
    }
    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(0.215, 0.610, 0.355, 1);
    }
    .slide-fade-enter{
        opacity: 0;
        transform: translateX(20px);
    }
    .slide-fade-enter-to{
        opacity: 1;
        transform: translateX(0px);
    }
    .slide-fade-leave{
        opacity: 1;
    }
    .slide-fade-leave-to{
        opacity: 0;

    }

    .font-blue{
        color: $lightblue;
    }

    .row {

        &:before,
        &:after {
            display: flex;
        }
    }

    @media (min-width: 768px) {}

    a {
        color: $lightblue;
        text-decoration: none;
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover,
        &:focus {
            color: $darkblue;
            text-decoration: none;
        }
    }

    .form-control {
        border-radius: 0px;
        height: 40px;
    }





    .form-control {
        @media(max-width: 767px) {
            font-size: 16px !important;
        }
    }



    img {
        max-width: 100%;
    }

    .transition {

        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

    }



}
