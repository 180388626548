// main : stylesheet.scss
.custom-checkbox{
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;

    &.big{
        .checkmark{
            height: 30px;
            width: 30px;
            flex-basis: 30px;
            max-width: 30px;

            margin-right: 15px;
            &:after {

                width: 11px;
                height: 17px;

            }
        }

    }


  /* Hide the browser's default checkbox */
   input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: relative;
    margin-right: 5px;
    height: 18px;
    width: 18px;
    background-color: #eee;
    flex-basis:  18px;;
    max-width:  18px;;
    flex-grow: 0;
    flex-shrink: 0;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
   input:checked ~ .checkmark {
    background-color: $lightblue;
  }

  input:disabled ~ .checkmark {
    background-color: #fff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
   input:checked ~ .checkmark:after {
    display: block;
  }

  input:disabled ~ .checkmark:after {
    display: block;
    border-color: $lightblue;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
  }
}

#checkout-checkout{
    background: #e5e5e5;

    .buttons{
        margin: 10px 0;
    }

    #collapse-payment-address, #collapse-shipping-address{
        // .form-group{
        //     display: flex;
        //     align-items: center;
        //     margin-left: -15px;
        //     margin-right: -15px;
        // }

        // #account, #address{
        //     .form-group{
        //         display: block;

        //     }
        // }

        #router-new, #payment-new, #shipping-new{
            .col-sm-2, .col-sm-10{
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }


    #payment-existing, #shipping-existing{
        margin-bottom: 20px;
    }

    .radio{

        margin: 5px 0;
        label{
            display: inline-flex;
            align-items: center;
        }
        input{
            margin-right: 10px;
            position: relative;
            width: 30px;
            height: 30px;
        }

        input:before{
            content: '';
            position: absolute;
            margin-right: 5px;
            height: 30px;
            width: 30px;
            background-color: #eee;
            border-radius: 50%;
            flex-basis:  30px;
            max-width:  30px;
            flex-grow: 0;
            flex-shrink: 0;
        }

        input:after {
            content: "";
            position: absolute;
            display: none;
        }



        input:checked:after {
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #2196F3;
          }

    }


.checkout-box, .panel{
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 30px;
    .box-title, .panel-title{
        font-size: 25px;
        font-weight: bold;
        background: $lightblue;
        &.red{
            background: $red;
        }

        color: #fff;
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        @media(max-width: 991px){
            display: block;
            font-size: 22px;
           
        }
        @media(max-width: 575px){
            padding: 10px 15px;
            font-size: 18px;
            
        }


        a{
            color: #fff!important;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }

        .price{
            font-weight: bold;
            @media(max-width: 991px){
                padding-top: 10px;
                margin-top: 10px;
                border-top: 1px solid #5cccfb;
                display: block;
            }
            small{
                font-weight: 300;
                font-size: 15px;
            }
        }
    }

    .speed-box{
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        @media(max-width: 1199px){
            flex-wrap: wrap;
        }
        .download,.upload{
            padding: 20px 0;
            flex-basis: 50%;
            max-width: 50%;
            user-select: none;
            @media(max-width: 1199px){
                flex-basis: 100%;
                max-width: 100%;
            }
            @media(max-width: 767px){
                padding: 10px 0;
            }
            &:hover{
                background: #f9f9f9;
            }
            .speed{
                font-size: 30px;
                font-weight: bold;
                @media(max-width: 1199px){
                    font-size: 25px;
                }
                @media(max-width: 991px){
                    font-size: 22px;
                }
                @media(max-width: 767px){
                    img{
                        width: 15px;
                        height: 15px;
                    }
                }
                @media(max-width: 575px){
                    font-size: 18px;
                }
                
            }
            span{
                display: block;
                text-align: center;
                margin-top: 0px;
                color: #777;
                @media(max-width: 991px){
                    font-size: 14px;
                }
                @media(max-width: 575px){
                    font-size: 12px;
                }
            }
        }
        .download{
            border-right: 1px solid #e5e5e5;
            @media(max-width: 1199px){
                border-right: 0px solid #e5e5e5;
                border-bottom: 1px solid #e5e5e5;
            }
        }
    }

    .box-content, .panel-body{
        padding: 20px 30px;
        @media(max-width: 575px){
            padding: 20px 15px;
            font-size: 15px;
        }

        h2, legend{
            font-weight: bold;
            font-size: 20px;
        }

        .info-line{
            font-size: 16px;
            @media(max-width: 575px){
                font-size: 15px;
            }
            .info-heading{
                font-weight: bold;
                font-size: 18px;
                display: block;
                @media(max-width: 575px){
                    font-size: 15px;
                }
            }
        }

        .additional-options{
            font-size: 17px;
            font-weight: normal;
            @media(max-width: 1199px){
                font-size: 15px;
            }
            .price{
                font-size: 20px;
                font-weight: bold;
                @media(max-width: 1199px){
                    font-size: 18px;
                }
                @media(max-width: 991px){
                    flex-basis: 100%;
                    max-width: 100%;
                }
                flex-basis: 200px;
                    max-width: 200px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    text-align: right;

                    @media(max-width: 991px){
                        flex-basis: 100%;
                        max-width: 100%;
                        text-align: left;
                        font-size: 14px;
                    }

            }
        }

        .your-router{
            .title{
                font-weight: bold;
                font-size: 20px;
                @media(max-width: 991px){
                    font-size: 18px;
                }

            }
            .text{
                font-weight: 300;
                line-height: 1.5;
                margin-top: 20px;
                margin-bottom: 20px;
                @media(max-width: 991px){
                    margin-top:10px;
                    margin-bottom: 10px;
                    font-size: 14px;
                }
                @media(min-width: 768px){
                    display: block!important;
                }
                @media(max-width: 767px){
                    display: none;
                }
            }
            img{
                flex-basis: 200px;
                max-width: 200px;
                flex-grow: 0;
                flex-shrink: 0;
            }

            .custom-radio{
                .checkmark{
                    background: #eeeeee!important;
                    margin-right: 10px;
                }

                strong{
                    font-size: 20px;
                    @media(max-width: 767px){
                        font-size: 18px;
                    }
                    @media(max-width: 575px){
                        font-size: 15px;
                    }
                }
            }
        }

        .care-level{
            margin-bottom: 30px;
            .level{
                flex-basis: 50%;
                max-width: 50%;
                @media(max-width: 1199px){
                    flex-basis: 100%;
                    max-width: 100%;
                }
                .custom-radio{
                    .checkmark{
                        background: #eeeeee!important;
                        margin-right: 10px;
                    }

                    strong{
                        font-size: 20px;
                        @media(max-width: 767px){
                            font-size: 18px;
                        }
                        @media(max-width: 575px){
                            font-size: 15px;
                        }
                    }
                }
                .level-desc{
                    padding-right: 30px;
                    font-size: 14px;
                    @media(max-width: 575px){
                        font-size: 13px;
                    }
                    p{
                        margin-bottom:5px;
                        line-height: 1.2;

                    }
                }
            }

        }

        .accordion{
            margin-bottom: 20px;
            .card{
                border: 0px solid #e5e5e5;
                .card-header{

                    background: $lightblue;
                    padding: 20px;
                    @media(max-width: 575px){
                        padding: 10px;
                    }
                    border: 1px solid $lightblue;
                    margin-bottom: 0;
                    position: relative;
                    padding-right: 80px;
                    cursor: pointer;
                    border-top-left-radius:10px;
                    border-top-right-radius:10px;

                    a{
                        font-weight: bold;
                        color: #fff;
                    }

                    &:after{
                        content: '\f078';
                        font-family: FontAwesome;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        width: 60px;
                        font-size: 18px;
                        text-align: center;
                        color: #fff;
                    }

                    &.collapsed{
                        background: #fff;
                        border-radius:10px;
                        border: 1px solid #e5e5e5;
                        a{
                            color: $lightblue;
                        }
                        &:after{
                            color: $lightblue;
                        }
                    }
                }

                .card-body{
                    border: 1px solid #e5e5e5;
                    border-top: 0;
                    border-bottom-left-radius:10px;
                    border-bottom-right-radius:10px;
                }
            }
        }

    }
}

}

.order-summary-toggle{
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    box-shadow: 0 0rem 1rem rgba(0,0,0,.4)!important;
    z-index: 999;
    .title{
        padding: 13px 20px;
        font-size: 18px;
        position: relative;
        height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #fff;
        background: #000;
        font-weight: bold;
        &:before{
            content: "\f102";
            font-family: FontAwesome;
            font-size: 25px;
            color: #fff;
            width: 50px;
            height: 100%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            background: $red;
        }
    }
}

#order-summary{
    border-radius: 20px;
    background: #fff;
    overflow: hidden;
    position: sticky;
    @media(max-width: 767px){
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-shadow: 0 0rem 1rem rgba(0,0,0,.15)!important;
        z-index: 1000;
        border-radius: 0;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
        &.open{
            overflow: scroll;
            opacity:1;
            visibility: visible;

            .title{
                position: relative;
                &:before{
                    content: "\f00d";
                    font-family: FontAwesome;
                    font-size: 25px;
                    color: #fff;
                    width: 50px;
                    height: 100%;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 0;
                    right: 0;
                    background: $red;
                }
            }
        }
    }

    .info{
        position: relative;
        display: block;
        z-index: 10;

        .order-summary-container{
            position: relative;
            display: block;
            z-index: 10;
        }
        .order-summary-loader{
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            background: #fff!important;
            z-index: 50;
            top: 0;
            left: 0;

            @media(max-width: 767px){
                display: none;
            }

            .inner{
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                background: #fff!important;
                z-index: 50;
                top: 0;
                left: 0;
                img{
                    width: 70px;
                    flex-basis: 70px;
                    max-width: 70px;
                }
            }
        }
    }



    top: 20px;
    .title{
        font-size: 25px;
        font-weight: bold;
        background: $lightblue;
        color: #fff;
        padding: 20px 30px;
        @media(max-width: 767px){
            padding: 13px 20px;
        }
        
        
    }
    .summary{
        padding: 20px 30px;
        @media(max-width: 767px){
            padding: 20px 20px;
        }
        
        .sub-title{
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin: 2px 0;
                padding: 4px 0;
                &.border-top{
                    border-top: 1px solid #e4e4e4;
                }
                &.border-bottom{
                    border-bottom: 1px solid #e4e4e4;
                }
                &.em{
                    font-style: italic;
                }

                small{
                    display: block;
                }

                .price{
                    flex-basis: 100px;
                    max-width: 100px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    text-align: right;
                    font-weight: 600;

                    &.big{
                        font-weight: bold;
                        color: $lightblue;
                    }

                }
            }
        }
    }

    .total{

        background: $red;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        padding: 20px 30px;
        @media(max-width: 767px){
            padding: 20px 20px;
        }
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .price{
                    flex-basis: 100px;
                    max-width: 100px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    text-align: right;
                    font-size: 20px;
                    font-weight: bold;
                }

                small{
                    font-size: 60%;
                    display: block;
                }
            }
        }
    }

    .continue-checkout-container{
        background: $red;
        text-align: right;
        padding: 0 30px 20px;

        .continue-checkout{
            padding: 15px 30px;
            margin: 0;
            margin: 0 20px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: bold;
            cursor: pointer;
        }
    }
}

.addon-info{
    flex-basis: 20px;
    max-width: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: 50%;
    color: #aaa;
    border: 2px solid #ccc;
    background: #fff;
    font-size: 13px;
    margin-left: 10px;
    transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover{
        color: $lightblue;
    }
}
