// main : stylesheet.scss

.all-packages{

    .fade-enter-active, .fade-leave-active {
        transition: 0.5s opacity cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
        transition: 0s opacity cubic-bezier(0.165, 0.84, 0.44, 1);
    }



    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .packages-grid{

        &.bg{
            background-image: url('/image/fibre.jpg');
            background-size: cover;
            background-position: 50% 50%;
        }


        .section-title{
            background: rgba(0, 81, 159, 0.6);
            padding: 40px 0;
            @media (max-width: 767px) {
                padding: 30px 0;
            }
            @media (max-width: 575px) {
                padding: 20px 0;
            }
            h2{
                color: #fff;
                font-size: 50px;
                @media (max-width: 991px) {
                    font-size: 40px;
                }
                @media (max-width: 767px) {
                    font-size: 30px;
                    font-weight: bold;
                }
            }
            p{
                font-weight: 300;
                font-size: 24px;
                color: #fff;
                margin: 0;
                @media (max-width: 991px) {
                    font-size: 22px;
                }
                @media (max-width: 767px) {
                    font-size: 20px;
                }
                @media (max-width: 767px) {
                    font-size: 18px;
                }
            }
        }

        .package{
            flex-basis: 100%;
            max-width: 100%;
            border-radius: 20px;
            overflow: hidden;
            background: #fff;
            margin-bottom: 30px;
            transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);




            .top{
                background: $lightblue;
                flex-basis: 105px;
                width: 100%;
                padding: 10px 30px;
                @media (max-width: 1199px) {
                    flex-basis: 80px;
                }
                @media (max-width: 575px) {
                    padding: 10px 20px;
                }
                h3{
                    font-size: 24px;
                    font-weight: bold;
                    color: #fff;
                    margin: 0;
                    margin-right: 20px;
                    @media (max-width: 1199px) {
                        font-size: 22px;
                    }
                    @media (max-width: 991px) {
                        font-size: 20px;
                    }
                }

                .img{
                    flex-basis: 60px;
                    max-width: 60px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    @media (max-width: 1199px) {
                        flex-basis: 40px;
                        max-width: 40px;
                    }
                    @media (max-width: 991px) {
                        flex-basis: 40px;
                        max-width: 40px;
                    }
                    img{
                        width: 100%;

                    }
                }


            }

            .bottom{
                padding: 20px 30px;
                width: 100%;
                .short{
                    max-width: 100%;
                }
                @media (max-width: 1199px) {
                    font-size: 14px;
                }
                @media (max-width: 575px) {
                    padding: 20px 20px;
                }
                &.no-padding{
                    padding: 0;
                    .owl-carousel{
                        .item{
                            padding: 20px 30px;
                            @media (max-width: 575px) {
                                padding: 10px 20px;
                            }
                        }
                    }

                }
                .button{
                    border: 0px;
                    padding-left: 40px;
                    padding-right: 40px;
                    text-align: center;

                    &:hover, &:focus{
                        outline: none;
                    }

                    @media (max-width: 575px) {
                        font-size: 15px;
                    }
                }

                .owl-carousel{
                    transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
                    .owl-nav{
                        .owl-next, .owl-prev{
                            position: absolute;
                            top: 50%;
                            width: 60px;
                            text-align: center;
                            transform: translateY(-50%);
                            i{
                                font-size: 58px;
                                color: $red;
                            }

                            &:hover, &:focus{
                                outline: none;
                                background: none;
                                i{
                                    color: $red;
                                }
                            }
                        }

                        .owl-next{
                            right: 0;
                        }

                        .owl-prev{
                            left: 0;
                        }
                    }

                    .item{
                        ul{
                            .name{
                                font-size: 22px;
                                margin-bottom: 10px;
                                height: 50px;
                                display: flex; align-items: center;
                                justify-content: center;
                                @media (max-width: 575px) {
                                    font-size: 20px;
                                    height: 40px;
                                }
                            }
                            .monthly_price{
                                font-size: 35px;
                                color: $lightblue;
                                font-weight: bold;
                                height: 50px;
                                line-height: 1;
                                display: flex; align-items: center;
                                justify-content: center;
                                margin-bottom: 20px;
                                @media (max-width: 575px) {
                                    font-size: 30px;
                                    height: 40px;
                                }
                                small{
                                    display: block;
                                    font-size: 13px;
                                    line-height: 1;
                                    color: #777;
                                }
                            }

                            .button{
                                margin: 0;
                            }

                            li{
                                @media (max-width: 575px) {
                                   font-size: 15px;
                                   line-height: 1.2;
                                }
                                .sub-title{
                                    font-size: 18px;
                                    @media (max-width: 575px) {
                                        font-size: 16px;
                                     }
                                }
                                p{
                                    margin-bottom: 2px;
                                }
                            }
                        }
                    }
                }
            }


        }

  }


  .packages-table{
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: calc(100% - 200px);
      .package-row{
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-basis: 100%;
          background: #fff;
          position: relative;
          z-index: 5;
          transform: scale(1);
          border: 1px solid transparent;
          border-bottom: 1px solid #e4e4e4;
          border-top: 1px solid #e4e4e4;
          margin-top: -1px;
          transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
          flex-wrap: wrap;
          box-shadow: 0 0 0px rgba(0,0,0,0.2);
          &:hover, &.open{
              background: #f9f9f9;
              //transform: scale(1.02);
              border-left: 1px solid #f9f9f9;
              border-right: 1px solid #f9f9f9;
              border: 1px solid #f9f9f9;
              z-index: 10;
          }

          &.open{
              //transform: scale(1.02);
              box-shadow: 0 0 30px rgba(0,0,0,0.2);
              background: #ffffff;
          }

          &.table-header{
              font-weight: 600;
              position: sticky;
              background: #fff;
              top: 0;
              z-index: 100;
              border: 1px solid transparent;
              border-bottom: 1px solid #e4e4e4;
              &:before, &:after{
                  display: none;
              }
              >div {
                  padding: 15px 10px;
                  font-size: 14px;
                  cursor: pointer;
                  color: #000;
                  &:hover, &:focus{
                      color: $lightblue;
                  }

                  &.active{
                      color: $lightblue;
                  }
              }
              &:hover{
                  background: #fff;
                  transform: scale(1);
                  border-left: 1px solid transparent;
                  border-right: 1px solid transparent;
              }


          }
          >div{
              padding: 5px 10px;
              line-height: 1.3;
              flex-grow: 0;
              flex-shrink: 0;
              p{
                  margin-bottom: 2px;
              }

              &.package-extras{
                  display: block;
                  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
                  z-index: 10;
                  flex-basis: 100%;
                  flex-grow: 0;
                  flex-shrink: 0;
                  max-width: 100%;
                  padding: 0;
                  form{
                      padding: 40px 20px 10px;
                      max-width: 700px;
                      margin: 0 auto;
                  }

                  .addon-add-to-cart{
                      margin: 0;
                      transform: translateY(-50%);
                  }

              }

              &.p-name{
                  flex-grow: 1;
                  flex-shrink: 1;
                  font-weight: 600;
                  padding-left: 20px;
                  font-size: 17px;
                  color: #000;
                  flex-basis: calc(100% - ( 5 * 120px ) - 170px );
                  max-width: calc(100% - ( 5 * 120px ) - 170px );
                  @media (max-width: 1199px) {
                      font-size: 16px;
                   }
              }

              &.p-monthly{
                  flex-basis: 120px;
                  max-width: 120px;
                  text-align: center;
              }

              &.p-down{
                  flex-basis: 120px;
                  max-width: 120px;
                  text-align: center;
              }

              &.p-up{
                  flex-basis: 120px;
                  max-width: 120px;
                  text-align: center;
              }

              &.p-term{
                  flex-basis: 120px;
                  max-width: 120px;
                  text-align: center;
                  line-height: 1.2;
              }

              &.p-fee{
                  flex-basis: 120px;
                  max-width: 120px;
                  text-align: center;
              }

              &.p-info{
                  flex-basis: 200px;
                  max-width: 200px;
              }

              &.p-form{
                  flex-basis: 170px;
                  max-width:170px;
                  text-align: center;

                  .button{
                      border: 0px;
                      text-align: center;

                      &:hover, &:focus{
                          outline: none;
                      }
                  }
              }


          }

      }
  }


}
