// compileCompressed : ../stylesheet.css
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');
@import '_colours';
@import '_header';
@import '_footer';
@import '_home';
@import '_body';
@import '_mixins';
@import '_buttons';
@import '_packages';
@import '_section';
@import '_mainbanner';
@import '_checkout';
@import '_orderinfo';
@import '_blog';
@import '_flats';
@import '_faqs';
@import '_leasedlines';
@import '_modals';



  .alert-success {
    @include alert-variant(#f4f4f4, #5fad35, #333);
  }

  .alert-info {
    @include alert-variant(#f4f4f4, $lightblue, #333);
  }

  .alert-warning {
    @include alert-variant(#f4f4f4, $red, #333);
  }

  .alert-danger {
    @include alert-variant(#f4f4f4, #f40001, #333);
  }


.font-black {
    font-weight: 900;
}

.font-bold {
    font-weight: bold;
}

.font-normal {
    font-weight: 400;
}

.font-light {
    font-weight: 300;
}

.font-lightblue {
    color: $lightblue;
}

.text-uppercase {
    text-transform: uppercase;
}

#consult-form{

    .form-control{
        border-radius: 30px;
        height: 50px;
        padding: 10px 30px;
        border: 0px;
        box-shadow: none;
        outline: none;
        &:hover, &:focus{
            border: 0;
            box-shadow: none;
            outline: none;
        }
    }

    textarea.form-control{
        height: auto;
    }

    .error{
        margin-bottom: 10px;
        em{
            display: block;
            text-align: center;
            margin-bottom: 3px;
        }
    }
}

#register-interest-form{
    .well{
        .custom-checkbox {
            .checkmark{
                background: #fff;
            }
            &:hover{
                .checkmark{
                background: #eee;
                }
            }
        }
        .custom-checkbox input:checked ~ .checkmark{
            background: $red;
        }
        h5{
            font-weight: bold;
            margin-bottom: 20px;
        }
        padding: 20px;
        text-align: left;
        border: 2px solid #fff;
        border-radius: 20px;
        margin-bottom: 30px;
        background: #2599ca;
        .form-control{
           
            border: 0px;
            box-shadow: none;
            outline: none;
            
            &:hover, &:focus{
                border: 0;
                box-shadow: none;
                outline: none;
            }
        }
    }

    .error{
        margin-bottom: 30px;
        em{
            display: block;
            margin-bottom: 5px;
        }
    }
}

#content{
    margin-bottom: 2rem;
}






#gigabit-voucher{

  .input-with-button{

      justify-content: flex-start;

      .form-control{
         background: #f3efef;
      }
      button{
          background: $lightblue;
          &:hover, &:focus{
              background: darken($lightblue, 10%);
          }

          
      }
  }
  }

  .main-heading{
      padding: 50px 0;
      h1{
          font-size: 50px;
      }
  }




#order-archive{
    .table td, .table th {
        padding: .75rem;
        vertical-align: middle;
        border-top: 1px solid #dee2e6;
    }
}






.input-with-button{
    display: flex;
    align-items: stretch;
    justify-content: center;

    @media(max-width: 575px){
        flex-wrap: wrap;
    }

    .form-control{
        height: auto;
        padding-left: 20px;
        padding-right: 30px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border: 0px;
        outline: none;
        box-shadow: none;
        flex-basis: 180px;
        max-width: 180px;
        @media(max-width: 575px){
            flex-basis: 100%;
            max-width: 100%;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            border-bottom-left-radius: 0;
            text-align: center;
            padding-left: 30px;
        }
    }
    button{
        flex-shrink: 0;
        box-shadow: none;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        padding: 10px 30px 10px 20px;;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        &.red{
            background: $red;
            &:hover, &:focus{
                background: darken($red, 10%);
            }
        }
        &.lightblue{
            background: $lightblue;
            &:hover, &:focus{
                background: darken($lightblue, 10%);
            }
        }

        @media(max-width: 575px){
            flex-basis: 100%;
            max-width: 100%;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            border-top-right-radius: 0;
        }

    }
}

.postcode-search-form{
    color: #fff;
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-top: 30px;
    position: relative;
    .inner-form{
        display: inline-block;
        margin: 0 auto;
        position: relative;
        z-index: 10;
        color: #fff;
    }

    .form-bg{
        position: absolute;
        z-index: 5;
        top: 70%;
        left: 70%;
        transform: translate(-50%,-50%);

    }


}

.custom-select-input{
    select{
        width: 55px;
        padding: 5px 5px;
    }
}




.custom-radio{
        display: inline-flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        user-select: none;

        &.big{
            .checkmark{
                height: 30px;
                width: 30px;
                flex-basis: 30px;
                max-width: 30px;
                &:after {

                    width: 16px;
                    height: 16px;

                }
            }

        }

      /* Hide the browser's default radio button */
       input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      /* Create a custom radio button */
      .checkmark {
        position: relative;
        margin-right: 5px;
        height: 18px;
        width: 18px;
        background-color: #eee;
        border-radius: 50%;
        flex-basis:  18px;
    max-width:  18px;
    flex-grow: 0;
    flex-shrink: 0;


      }

      /* On mouse-over, add a grey background color */
      &:hover input ~ .checkmark {
        background-color: #ccc;
      }

      /* When the radio button is checked, add a blue background */
      input:checked ~ .checkmark {
        background-color: #fff;
      }

      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the indicator (dot/circle) when checked */
     input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the indicator (dot/circle) */
       .checkmark:after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #2196F3;
      }
}

.breadcrumb{
    margin-top: 2rem!important;
    margin-bottom: 2rem!important;
    border-radius: 0px;
    border-left: 10px solid #e4e4e4;
    background: #f4f4f4;
    padding: 10px 20px;
    >li+li:before {
        padding: 0 5px;
        color: #ccc;
        content: "/\00a0";
    }
    li{

        a{
            color: #666;
            &:hover{
                color: $lightblue;
            }
        }
    }
}
.postcode-lookup{


    .loader{
        img{
            width: 80px;
        }
    }

}
