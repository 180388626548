.blog{
  .blog_grid_holder{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    .blog_item{

      display: flex;
      align-items: stretch;
      
      .summary{
        flex-basis: 100%;
        background: #fff;
        border-radius: 30px;
        overflow: hidden;
        box-shadow: 5px 5px 15px rgba(0,0,0,0.1);
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        position: relative;
        .image{
          height: 200px;
          background-size: cover;
          background-position: 50% 50%;
          display: block;
          margin: 0;
          background-color: #fff;
          background-blend-mode: multiply;
          position: relative;
          transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          @media(max-width: 575px){
            height: 45vw;
          }
        }
        .blog_title{
          margin: 0;
          a{
            color: #fff;
            padding: 10px 20px;
            background: $lightblue;
            display: block;
          }
        }
        .blog_stats{
          padding-top: 10px;
          padding-left: 20px;
          padding-right: 20px;
        }
        .bottom{
          padding: 10px 20px;
          padding-bottom: 70px;
          .button{
            position: absolute;
            left: 20px;
            bottom: 20px;
          }
        }

        &:hover{
          box-shadow: 0px 0px 0px rgba(0,0,0,0.1);
          .image{
            background-color: #ccc;
          }
        }
      }
    }
  }

  &.blog_post{
    .main_thumb{
      height: 250px;
      background-size: cover;
          background-position: 50% 50%;
          border-radius: 30px;
          box-shadow: 5px 5px 15px rgba(0,0,0,0.1);
          margin-bottom: 30px;
          @media(max-width: 991px){
            height: 200px;
          }
        @media(max-width: 776px){
          height: 45vw;
        }
    }
  }
  .column-3 {
    .blog_item{
      flex-basis: 33.33333%;
        max-width: 33.33333%;
    }
  }

  @media screen and (max-width: 991px) {
    .column-3 .blog_item, .column-4 .blog_item {
        width: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
  }
  @media screen and (max-width: 776px) {
    .column-3 .blog_item, .column-4 .blog_item {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
  }
}