#header{
    position: relative;
    padding: 20px 0;
    box-shadow: 0 1px 5px 2px rgba(0,0,0,0.1);
    @media(max-width: 1199px){
        padding: 10px 0 0;
    }
    @media(max-width: 767px){
        padding: 0;
    }

    
    .logo{
        flex-basis: 150px;
            max-width: 150px;
            flex-grow: 0;
            flex-shrink: 0;
        a{
            display: flex;
            flex-basis: 100%;
            max-width: 100%;
            flex-grow: 0;
            flex-shrink: 0;
            align-items: center;
            justify-content: flex-start;
            img{
                flex-basis: 100%;
                max-width: 100%;
            }
        }
        @media(max-width: 767px){
            margin: 15px 0;
            order: 2;
        }
        
    }

  
    .menu{
        
        flex-grow: 1;
        margin-left:30px;
        
        @media(max-width: 1199px){
            order: 3;
            padding: 10px 0;
            margin: 0;
            text-align: center;
           
            margin-top: 10px;
            ul{justify-content: center;}
            
        }
        @media(max-width: 767px){
            flex-basis: 100px;
            max-width: 100px;
           &:before{
               display: none;
           }
        }
        > ul{
            margin: 0;
            position: relative;
            z-index: 10;
            li{
                margin-right: 0;
                margin-left: .5rem;
                a{
                    color: #000;
                    padding: 6px 10px;
                    display: block;
                    font-size: 15px;
                    font-weight: 600;
                    &:hover, &:focus{
                        color: $red;
                    }
                }

                &.dropdown{
                    a{
                        padding: 6px 20px;
                    }
                }

                &.dropdown.show{

                    
                   
                    .dropdown-menu{
                        
                        margin-top: 0px;
                        max-width: 400px;
                        background: $lightblue;
                        width: 300px;
                        border-radius: 00px;
                        overflow: hidden;
                        border: 0px;
                        padding: 0;
                        a{
                            padding: 8px 20px;
                            display: block;
                            color: #fff;

                            &:hover, &:focus{
                                background: $red;
                            }
                        }
                    }
                   
                }
            }
        }
    }


}

.top-bar{
    background: $lightblue;
    padding: 5px 0;
    @media(max-width: 767px){
        padding: 0px 0;
    }
    .links{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width: 1199px){
            display: flex;
           
        }
        
        @media(max-width: 767px){
            flex-basis: 100%;
            max-width: 100%;
            display: block;
            order: 1;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            height: 45px;
            ul{
                position: relative;
                z-index: 2;
            }
           
            
        }
        .dropdown{
            a{
                padding: 6px 20px;
            }
        }

        .dropdown.show{
           
            .dropdown-menu{
                
                margin-top: 0px;
                background: $lightblue;
                border-radius: 0px;
                overflow: hidden;
                border: 0px;
                padding: 0;
                a{
                    padding: 8px 15px;
                    display: block;
                    color: #fff;
                    text-align: center;
                    &:hover, &:focus{
                        background: $red;
                    }
                }
            }
           
        }
        .top{
            
            ul{
                margin-bottom: 10px;
                @media(max-width: 1199px){
                    margin-bottom: 0;
                   
                }
                @media(max-width: 991px){
                    margin-bottom: 5px;
                   
                }
                li{
                    padding-left: 20px;
                    a{
                        font-weight: 600;
                        color: #fff;
                        display: flex;
                        padding: 8px 15px;
                        display: block;
                        align-items: center;
                        line-height: 1;
                        i{
                            margin-right: 8px;
                            @media(max-width: 767px){
                                margin-right: 0;
                               
                            }
                        }
                        @media(max-width: 1199px){
                            font-weight: 400;
                           
                        }
                        font-size: 16px;

                        &:hover{
                            color: $red;
                            @media(max-width: 767px){
                                color: #fff;
                               
                            }
                        }
                    }
                }
            }
        }
        .bottom{
            @media(max-width: 1199px){
               margin-left: 20px;
               
            }
            ul{
                margin-bottom: 0;
                li{
                    padding-right: 20px;
                    a{
                        font-weight: 600;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        line-height: 1;
                        i{
                            margin-right: 8px;
                            @media(max-width: 767px){
                                margin-right: 0;
                               
                            }
                        }
                        @media(max-width: 1199px){
                            font-weight: 400;
                           
                        }
                        font-size: 16px;
                        &:hover{
                            color: $red;
                        }
                    }
                }
            }
        }

        @media(max-width: 767px){

            .top,.bottom{
                display: flex;
                align-items: stretch;
                flex-basis: 50%;
                max-width: 50%;
                margin: 0;
                ul{
                    margin: 0;
                    li{
                        padding: 0!important;
                        margin: 0!important;
                        display: flex;
                        align-items: stretch;
                        width: 50px;
                        flex-basis: 50px;
                        max-width: 50px;
                        border-right: 1px solid #fff;
                        a{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0px;
                            flex-basis: 100%;
                            max-width: 100%;
                            .caret{
                                display: none;
                            }
                            &.dropdown-toggle::after{
                                display: none;
                            }
                            &:hover, &:focus{
                                background: $red;
                                i{
                                    color: #fff;
                                }
                            }
                            
                        }

                        .dropdown-menu{
                            left: 0;
                            margin-left: 0;
                        }

                        &:first-child{
                            border-left: 1px solid #fff;
                        }
                    }
                }
                
            }

            .top{
                justify-content: flex-end;

               
            }
            .bottom{
                justify-content: flex-start;
                
            }
            
           
        }
    }
}