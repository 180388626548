// main : stylesheet.scss

.order-info-thead{
    background: $lightblue;
    color: #fff;
    font-weight: bold;
    td{
        font-weight: bold;
        padding: 20px 30px;
    }
}

.order-info-body{
    td{
        padding: 20px 30px;
    }
}

.order-info-summary{
    .title{
        background: $lightblue;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        h4{
            margin: 0;
            font-weight: bold;
            font-size: 18px;
        }
        margin: 0 -30px;
        margin-bottom: 30px;
        padding: 20px 30px;
    }
    padding: 0 30px;
    border: 1px solid #e4e4e4;
    margin-bottom: 20px;
    .sub-title{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 2px 0;
            padding: 4px 0;
            &.border-top{
                border-top: 1px solid #e4e4e4;
            }
            &.border-bottom{
                border-bottom: 1px solid #e4e4e4;
            }
            &.em{
                font-style: italic;
            }

            small{
                display: block;
            }

            .price{
                flex-basis: 100px;
                max-width: 100px;
                flex-shrink: 0;
                flex-grow: 0;
                text-align: right;
                font-weight: 600;

                &.big{
                    font-weight: bold;
                    color: $lightblue;
                }

            }
        }
    }
    .total{

        background: $red;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        margin: 0 -30px;
        margin-top: 30px;
        padding: 10px 30px;
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .price{
                    flex-basis: 100px;
                    max-width: 100px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    text-align: right;
                    font-size: 20px;
                    font-weight: bold;
                }

                small{
                    font-size: 60%;
                    display: block;
                }
            }
        }
    }
}
