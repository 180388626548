$red: #e53154;
$lightblue: #25aae1; 
$darkgrey: #414141;


$black: #1e1e23;
$gray: #5a5a5e;
$purple: #7653b7;
$darkpurple: #4c3576;
$green: #3ac2a7;
$darkblue: #2a8c9b;
$blue: #4abdcf;
$pink: #a247a1;