// main : stylesheet.scss

@mixin alert-variant($background, $border, $color) {
    color: $color;
    background: $background;
    border-color: $border;
    border-radius: 0px;

    border: 0px;

    border-left: 10px solid $border;

    .alert-link {
      color: darken($color, 10%);
    }
  }
