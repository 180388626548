// main  : stylesheet.scss

.flats{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
  .flat{
    //flex:180px;
    .inner{
      background: #25aae1;
      color: white;
      display: flex;
      flex-direction: column;
      width:120px;
      @media (max-width: 768px){
        width: 105px;
      }
      margin:10px;
      padding:15px;
      img{
        width: 55px;
        @media (max-width: 768px){
          width: 40px;
        }
        max-width: 100%;
      }
      border-radius: 15px;
      .text{
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
      }
      .sub{
        font-size: 13px;
      }
      &.selected{
        border:4px solid #ed145b;
        //width: 132px;
      }
    }


  }
}
