// main : stylesheet.scss

#modal-agree{
    border-radius: 0px;
    border: 5px solid #ccc;
    .modal-header{
        display: block;
        .modal-title{
            color: $red;
            font-weight: bold;
        }
    }
    .modal-content{
        font-size: 13px;
    }
}





.filter-modal{



    .modal-content {

        .modal-header{
            h5{
                color: $red;
                font-size: 25px;
                margin-bottom: 20px;
                font-weight: bold;
            }
        }

        .modal-body {
            h5.filter-title{
                color: #333;
                margin-bottom: 10px;
                font-size: 17px;
                padding-bottom: 10px;
                border-bottom: 1px solid #e4e4e4;
            }
        }
    }

    .options{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        label{
            flex-basis: 50%;
            max-width: 50%;
        }
    }

}

.modal-addon{
    border-radius: 0px;
    .modal-content{
        border-radius: 0px;
        border: 5px solid #ccc;
        .modal-header{
            border-radius: 0px;
            border: 0;
            padding: 20px 30px 10px;
        }
        .modal-body{
            border-radius: 1px;
            border: 0;
            padding: 0px 30px 40px;
            h5{
                font-weight: bold;
                color: $red;
            }
            font-size: 14px;
        }

    }
}
