#footer{
    padding: 60px 0 100px 0;
    background: #414141;
    border-top: 20px solid $red;
    color: #fff;
    
    @media (max-width: 991px) {
        padding: 60px 0 60px 0;
    }
    @media (max-width: 767px) {
        padding: 40px 0;
        padding-bottom: 100px;
    }
    .social{
        margin-bottom: 10px;
        ul{
            margin: 0;
            padding: 0;
            li{
                a{
                    width: 30px; 
                    height: 30px;
                    background: #fff;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i{
                        color: #000;
                        font-size: 20px;
                    }

                    &:hover, &:focus{
                        background: $lightblue;
                        i{
                            color: #fff;
                        }
                    }
                }
                
            }
        }
    }

    .logo{
        flex-basis: 150px;
            max-width: 150px;
            flex-grow: 0;
            flex-shrink: 0;

            @media (max-width: 767px) {
                margin: 0 auto;
            }
        a{
            display: flex;
            flex-basis: 100%;
            max-width: 100%;
            flex-grow: 0;
            flex-shrink: 0;
            align-items: center;
            justify-content: flex-start;
            img{
                flex-basis: 100%;
                max-width: 100%;
            }

            @media (max-width: 575px) {
                justify-content: center;
            }
        }
    }

    .menu{
        flex-grow: 1;
        margin: 0 40px;
        ul{
            margin: 0;
            li{
                padding: 0 10px;
                a{
                    color: #fff;
                    font-size: 15px;
                    &:hover, &:focus{
                        color: $lightblue;
                    }
                }

                &.dropdown{
                    a{
                        padding: 6px 20px;
                    }
                }

                &.dropdown.show{

                    
                   
                    .dropdown-menu{
                        
                        margin-top: 0px;
                        max-width: 400px;
                        background: $lightblue;
                        width: 300px;
                        border-radius: 00px;
                        overflow: hidden;
                        border: 0px;
                        padding: 0;
                        a{
                            padding: 8px 20px;
                            display: block;
                            color: #fff;

                            &:hover, &:focus{
                                background: $red;
                            }
                        }
                    }
                   
                }
            }
        }
    }

    
}


#cookieBar{
    line-height: 1.2;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    z-index: 1000;
    animation-name: showcookiebar;
    animation-duration: 1s;
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 575px) {
            display: block;
        }
        .button{
            border: 2px solid #fff;
            background: $lightblue;
            margin-left: 20px;
            @media (max-width: 575px) {
                margin-left: 0;
                
                margin: 0 auto;
                margin-top: 10px;
                font-size: 15px;
            }
            cursor: pointer;
            color: #fff;
            flex-shrink: 0;
            &:hover{
                border-color: $red;
                background: $red;
            }
        }
        span{
            color: #fff;
            flex-grow: 1;
            @media (max-width: 575px) {
                display: block;
            }
            a{
                color:  $red
            }
            a:hover{
                color:  #ffffff;
            }
        }
    }
}

@keyframes showcookiebar {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
