// main : stylesheet.scss
.section {

    overflow: hidden;
    &.bg-cover{
        background-size: cover;
        background-position: 50% 50%;
        border-bottom: 0px!important;
    }

    &.bg-left{
        background-size: auto 100%;
        border-bottom: 0px!important;
        background-repeat: no-repeat;
    }

    &.mobile-no-bg{
        @media (max-width: 991px) {
            background-image: none!important;
        }
       
    }

    
   
    

    line-height: 1.6;;
    padding: 60px 0;
    @media (max-width: 991px) {
        padding: 40px 0;
    }
    @media (max-width: 767px) {
        padding: 30px 0;
    }
    @media (max-width: 575px) {
        padding: 20px 0;
    }

    &.small-padding{
        padding: 30px 0;
        @media (max-width: 575px) {
            padding: 20px 0;
        }
    }

    &.text-section{
        color: #53514d;
        &.inner-shadow{
            box-shadow: 
        inset 0px 11px 18px -10px rgba(0,0,0,0.5),
        inset 0px -11px 18px -10px rgba(0,0,0,0.5),
        }
        &.big-text{
            font-size: 18px;
            @media (max-width: 991px) {
                font-size: 17px;
            }
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }

        h1,h2{
            color: $lightblue;
            font-size: 50px;
            @media (max-width: 1199px) {
                font-size: 40px;
            }
            @media (max-width: 991px) {
                font-size: 30px;
            }
            @media (max-width: 767px) {
                font-size: 30px;
                font-weight: bold;
            }
            &.stored{
              font-weight: 700;
            }

        }

        

        h3{
            font-weight: 300;
            line-height: 1.4;
            @media (max-width: 1199px) {
                font-size: 1.65rem;
            }
            @media (max-width: 991px) {
                font-size: 1.45rem;
            }
            @media (max-width: 767px) {
                font-size: 1.25rem;
                font-weight: 400;
            }

        }
        .block-list{
            margin-top: 80px;
            @media (max-width: 991px) {
                margin-top: 50px;
            }
            @media (max-width: 767px) {
                margin-top: 30px;
            }
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
                column-count: 2;
                column-gap: 100px;
                @media (max-width: 991px) {
                    column-gap: 40px;
                }
                @media (max-width: 767px) {
                    column-count: 1;
                    column-gap: 0;
                }
                li{
                    
                    padding: 30px 50px 50px 80px;
                    break-inside: avoid;
                    color :#000;
                    font-size: 18px;
                    line-height: 1.3;
                    z-index: 1; 
                   
                    position: relative;
                    @media (max-width: 991px) {
                        padding: 20px 30px 30px 80px;
                        font-size: 17px;
                    }
                    @media (max-width: 767px) {
                        padding: 20px 0 30px 60px;
                        font-size: 16px;
                    }
                    @media (max-width: 575px) {
                        padding: 20px 0 35px 50px;
                        font-size: 15px;
                    }
                    &:after{
                        content: '';
                        position: absolute;
                        height: 40px;
                        width: 40px;
                        border: 5px solid #fff;
                        box-shadow: 0 0 10px rgba(0,0,0,0.3);
                        background: $red;
                        top: 30px;
                        left: 0;
                        border-radius: 50%;;
                        @media (max-width: 767px) {
                            height: 30px;
                            width: 30px;
                            border: 4px solid #fff;
                        }
                    }
                    
                   
                }
            }
        }
        .inner-block{
            padding: 60px 60px;
            @media (max-width: 1199px) {
                padding: 50px 50px;
            }
            @media (max-width: 991px) {
                padding: 40px 40px;
            }
            @media (max-width: 767px) {
                padding: 30px 20px;
            }
        
            blockquote{
                font-weight: bold;
                padding-left: 20px;
                border-left: 3px solid #04cddd; 
                margin-top: 35px;
            }
            &.back{
                background: #fff;
                border: 1px solid #e4e4e4;
                border-right: 0;
                position: relative;
                @media (max-width: 767px) {
                    border:  0;
                }
                &:after{
                    content: '';
                    height: calc(100% + 2px);
                    width: 150px;
                    position: absolute;
                    top: -1px;
                    right: -150px;
                    z-index: 0;
                    background: #fff;
                    border: 1px solid #e4e4e4;
                    border-left: 0;
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }
            &.big-font{
                font-size: 20px;
                font-weight: 300;
                background: #fff;
                @media (max-width: 1199px) {
                    font-size: 18px;
                }
                @media (max-width: 991px) {
                    font-size: 17px;
                }
                @media (max-width: 767px) {
                    font-size: 16px;
                }
                @media (max-width: 575px) {
                    font-size: 15px;
                }
            }
            h2{
                font-weight: 900;
                font-size: 40px;
                margin-bottom: 20px;
                line-height: 1.3;
                @media (max-width: 1199px) {
                    font-size: 33px;
                }
                @media (max-width: 991px) {
                    font-size: 30px;
                }
                @media (max-width: 767px) {
                    font-size: 25px;
                }
               
            }

            .comparison{
                list-style: none;
                margin: 0;
                padding: 0;
                @media (max-width: 991px) {
                    margin-top: -40px;
                }
                    li{
                        display: flex;
                        align-items: stretch;
                        justify-content: space-between;
                        padding: 10px 0;
                        border-bottom: 1px solid #e4e4e4;
                        .left{
                            flex-grow: 1;
                            font-size: 18px;
                            font-weight: 600;
                            @media (max-width: 767px) {
                                font-size: 16px;
                            }
                            @media (max-width: 575px) {
                                font-size: 15px;
                            }
                        }
                        .column{
                            flex-basis: 100px;
                            max-width: 100px;
                            flex-shrink: 0;
                            flex-grow: 0;
                            text-align: center;
                            padding: 0 5px;
                            @media (max-width: 767px) {
                                flex-basis: 75px;
                                max-width: 75px;
                            }
                            @media (max-width: 575px) {
                                flex-basis: 60px;
                                max-width: 60px;
                            }
                            &.title{
                                font-size: 17px;
                                font-weight: bold;
                                line-height: 1.1;
                               
                                display: flex;
                                align-items: flex-end;
                                justify-content: center;
                                text-align: center;
                                @media (max-width: 767px) {
                                    font-size: 15px;
                                }
                                @media (max-width: 575px) {
                                    font-size: 13px;
                                }
                                @media (max-width: 380px) {
                                    font-size: 12px;
                                }
                            }
                            i{
                                font-size: 30px;
                                @media (max-width: 575px) {
                                    font-size: 25px;
                                }
                            }

                            .fa-check{
                                color: lightgreen;
                            }

                            .fa-times{
                                color: red;
                            }
                        }
                    }
            }
            
            .intro{
                max-width: 80%;
                display: block;
                margin: 0 auto;
                font-size: 23px;
                font-weight: 300;
                text-align: center;

                strong,b{
                    font-weight: bold;
                }
                @media (max-width: 1199px) {
                    max-width: 85%;
                    font-size: 20px;
                }
                @media (max-width: 991px) {
                    max-width: 90%;
                    font-size: 18px;
                                }
                @media (max-width: 767px) {
                    max-width: 100%;
                    font-size: 17px;
                }
                
                &.big{
                    max-width: 90%;
                    h2{
                        margin-bottom: 30px;
                    }
                }
                h2{
                    font-size: 50px;
                    margin-bottom: 10px;
                    margin-top: 0;
                    color: #000;
                    &.font-white{
                        color: #fff;
                    }

                    @media (max-width: 1199px) {
                        font-size: 45px;
                    }
                    @media (max-width: 991px) {
                        font-size: 40px;
                    }
                    @media (max-width: 767px) {
                        font-size: 35px;
                    }
                    @media (max-width: 575px) {
                        font-size: 25px;
                    }
                }
                
            }
            .ticks{
                ul{
                    padding:  0;
                    margin: 0;
                    margin-top: 20px;
                    list-style-type: none;
                    li{
                        font-size: 20px;
                        
                        position: relative;
                        padding-left: 50px;
                        margin-bottom: 20px;
                        @media (max-width: 1199px) {
                            font-size: 18px;
                            margin-bottom: 15px;
                        }
                        @media (max-width: 991px) {
                            font-size: 16px;
                            margin-bottom: 10px;
                            padding-left: 40px;
                        }
                        @media (max-width: 767px) {
                            font-size: 16px;
                        }
                        @media (max-width: 575px) {
                            font-size: 15px;
                        }
                        &:before{
                            content: '\f00c';
                            font-family: FontAwesome;
                            width: 40px;
                            height: 40px;
                            color: #fff;
                            font-size: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            top: -3px;
                            left: 0;
                            @media (max-width: 991px) {
                                font-size: 25px;
                                width: 40px;
                            height: 40px;
                            }
                        }
                    }
                }
            }
            &.lightblue{
                background: $lightblue;
                color: #fff;
                margin-top: 40px;
                
                h2{
                    color: #fff;
                    margin-top: 0;
                }
                .ticks.columns{
                    ul{
                        column-count: 2;
                        column-gap: 50px;
                        @media (max-width: 991px) {
                            column-count: 1;
                            column-gap: 0;
                        }
                    }
                }
            }
            
            &.gradient-blue{
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#03d1de+0,1599d0+100 */
                background: #03d1de; /* Old browsers */
                background: -moz-linear-gradient(top, #03d1de 0%, #1599d0 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, #03d1de 0%,#1599d0 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, #03d1de 0%,#1599d0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03d1de', endColorstr='#1599d0',GradientType=0 ); /* IE6-9 */
                font-weight: 400;
                color: #fff;
                margin-top: 100px;
                margin-bottom: 50px;
                h3{
                    color: #fff;
                    font-size: 35px;
                    font-weight: bold;
                    @media (max-width: 991px) {
                        font-size: 30px;
                        }
                        @media (max-width: 776px) {
                            font-size: 25px;
                            }
                }
                @media (max-width: 1199px) {
                    margin-top: 70px;
                    margin-bottom: 20px;
                }
                @media (max-width: 991px) {
                margin-bottom: 30px;
                }
                @media (max-width: 767px) {
                    margin-top: 0;
                    margin-bottom: 40;
                }
                h2{
                    color: #fff;
                }
                position: relative;
                box-shadow: 10px 10px 25px rgba(0,0,0,0.1);
                .tick{
                    height: 100px;
                    width: 100px;
                    bottom: 0;
                    position: absolute;
                    left: 0;
                    transform: translate(-50%, 50%);
                    background: $lightblue;
                    border: 5px solid #fff;
                    border-radius: 50%;
                    padding: 25px;
                    @media (max-width: 991px) {
                        height: 70px;
                    width: 70px;
                    border: 4px solid #fff;
                    padding: 15px;
                    }
                    @media (max-width: 575px) {
                        left: 50%;
                        transform: translate(-50%, 60%);
                    }
                }
            }
        }
    

    }

    &.grey{
        background-color: #dcdcdc;
        border-bottom: 1px solid #fff;
        color: #000;
        h2, h3{
            color: #000;

            &.font-blue{
                color: $lightblue;
            }
        }
    }

    &.lightblue{
        background-color: $lightblue;
        color: #fff;
        .title{
            font-size: 45px;
            @media (max-width: 1199px) {
                font-size: 40px;
            }
            @media (max-width: 991px) {
                font-size: 30px;
            }
            @media (max-width: 767px) {
                font-size: 30px;
            }
        }
    }

    &.extralightblue{
        background-color: #64c3ea;
        color: #fff;
        
        

        .title{
            font-size: 45px;
            color: #fff;
            margin-bottom: 20px;
            @media (max-width: 1199px) {
                font-size: 40px;
            }
            @media (max-width: 991px) {
                font-size: 30px;
            }
            @media (max-width: 767px) {
                font-size: 30px;
                font-weight: bold;
            }
        }
        .subtitle{
            color: #000;
            font-weight: 300;
            margin: 40px 0;
            font-size: 24px;
            line-height: 1.4;
            @media (max-width: 1199px) {
                font-size: 22px;
            }
            @media (max-width: 991px) {
                font-size: 20px;
                margin: 30px 0;
            }
            @media (max-width: 767px) {
                font-size: 15px;
                margin: 20px 0;
            }
        }
    }

    &.lightgrey{
        background: #e5e5e5;
        color: #000;
        h2, h3{
            color: #000;
        }
    }

    &.black-text{
        color: #000;

        h2, h3{
            color: #000;
        }
    }

    &.black{

        background-color: #000;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        color: #fff;
        h2, h3{
            color: #fff;
        }
    }

    &.black-with-bg{

        background-color: #000;

        color: #fff;
        h2, h3{
            color: #fff;
        }
    }

    &.extra-padding{
        padding: 100px 0;
        @media (max-width: 1199px) {
            padding: 80px 0;
        }
        @media (max-width: 991px) {
            padding: 60px 0;
        }
        @media (max-width: 767px) {
            padding: 40px 0;
        }
    }

    &.red{
        background-color: $red;
        color: #fff;
        h2{
            color: #fff;
        }
    }


    &.gradient-blue{
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#03d1de+0,1599d0+100 */
        background: #03d1de; /* Old browsers */
        background: -moz-linear-gradient(top, #03d1de 0%, #1599d0 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #03d1de 0%,#1599d0 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #03d1de 0%,#1599d0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03d1de', endColorstr='#1599d0',GradientType=0 ); /* IE6-9 */
        font-weight: 300;
        color: #fff;
        h2{
            color: #fff;
        }
      
        
    }

    &.gigabit{

        h3{
            font-size: 20px;
        }
    }

    .globe{
        @media (max-width: 767px) {
            width: 50%;
            margin: 10px auto;
        }
    }

    .search-container{
        height: 50px;
        @media (max-width: 575px) {
            height: 78px;
        }
    }

    .benefits{
        flex-wrap: wrap;
        .benefit{
            flex-basis: 20%;
            max-width: 20%;
            padding: 10px;
            text-align: center;
            @media (max-width: 1199px) {
                flex-basis: 33.33333%;
                max-width: 33.33333%;
            }

            @media (max-width: 767px) {
                flex-basis: 50%;
                max-width: 50%;
            }
            @media (max-width: 575px) {
                flex-basis: 100%;
                max-width: 100%;
            }

            .inner{
                padding: 0 10px;
            }

            .hexagon {
                position: relative;
                width: 160px;
                height: 92.38px;
                background-color: #35c3df;
                margin: 46.19px auto;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 80px;
                transition: transform 0.8s ease-in-out;
                transform-style: preserve-3d;
                &:before,
                &:after {
                content: "";
                position: absolute;
                width: 0;
                border-left: 80px solid transparent;
                border-right: 80px solid transparent;
              }

              &:before {
                bottom: 100%;
                left: 0;
                border-bottom: 46.19px solid #35c3df;
              }

              &:after {
                top: 100%;
                width: 0;
                left: 0;
                border-top: 46.19px solid #35c3df;
              }
                


              }

            h4{
                color: #fff;
                font-size: 16px;
                font-weight: bold;
            }

            p{
                font-size: 15px;
                font-weight: 300;
                color: #000;
            }

            &:hover{
                .hexagon{
                    transform: rotateY(180deg);
                    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                }
            }
        }

        &.home{
            .benefit{
                .hexagon{
                    padding: 0 40px;
                    background-color: rgba(255,255,255,0.3);
                    &:before {
                     
                        border-bottom: 46.19px solid rgba(255,255,255,0.3);
                      }
        
                      &:after {
                      
                        border-top: 46.19px solid rgba(255,255,255,0.3);
                      }
                }
                h4{
                    color: #000;
                   
                }
            }
        }

        &.alternative{
            .benefit{
                padding: 10px;
                text-align: center;
                flex-basis: 33.33333%;
                max-width: 33.33333%;
                @media (max-width: 767px) {
                    flex-basis: 100%;
                    max-width: 100%;
                    
                }
                .hexagon-container{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    .hexagon{
                        display: flex;
                        align-items: center;
                        justify-self: center;
                        
                        @media (max-width: 767px) {
                            margin-bottom: 55px;
                            
                        }
                        img{
                            width: 80%;
                            position: absolute;
                            z-index: 10;
                        }
                    }
                    
                }
                .number{
                    font-size: 40px;
                    font-weight: 800;
                    color: #fff;
                    @media (max-width: 767px) {
                        font-size: 30px;
                    }

                }
                .hex-text{
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    padding: 0 40px;
                    @media (max-width: 991px) {
                        
                        padding: 0 20px;
                    }
                    @media (max-width: 767px) {
                        display: block;
                        align-items: center;
                    }
                    
                    p{
                        font-weight: 300;
                        color: #fff;
                        font-size: 24px;
                        line-height: 1.4;
                        @media (max-width: 991px) {
                            font-size: 20px;
                        }
                        @media (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
                }
            }
            &:hover{
                .hexagon{
                    transform: none;
                    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                }
            }
        }
    }

    .icon-block{
        margin: 15px 0;
        img{
            width: 300px;
            @media (max-width: 1199px) {
                width: 250px;
            }
            @media (max-width: 991px) {
                width: 200px;
            }
            @media (max-width: 767px) {
                width: 180px;
            }
        }
        .text{
            .title{
                font-size: 35px;
                color: $lightblue;
                margin-bottom: 10px;
                font-weight: normal;
                @media (max-width: 1199px) {
                    font-size: 30px;
                }
                @media (max-width: 991px) {
                    font-size: 25px;
                }
                @media (max-width: 767px) {
                    font-weight: bold;
                }
            }
            font-size: 16px;
            font-weight: 300;
            max-width: 70%;
            margin: 0 auto;
            @media (max-width: 767px) {
                font-size: 15px;
                max-width: 90%;
            }
        }
    }


    .addonsList{
        border: 1px solid #e3e3e3;
        padding: 15px 20px 5px;
        position: relative;
        border-radius: 10px;
        > span.title{
            position: absolute;
            top: -12px;
            padding: 0 10px;
            background: #fff;
            display: inline-block;
            left: 50%;
            transform: translateX(-50%);
        }


        &.table-view{
            position: relative;
            padding: 50px;
            border: 2px solid #25aae1;
            background: #fff;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;


            .addons{
                flex-basis: 100%;
                max-width: 100%;

                    .price{
                        flex-basis: 150px;
                            max-width: 150px;
                            flex-grow: 0;
                            flex-shrink: 0;
                            text-align: right;

                    }

            }
            > span.title{
                position: absolute;
                top: 0;
                padding: 7px 20px;
                display: inline-block;
                left: 50%;
                font-size: 16px;
                border-radius: 20px;
                background: #fff;
                font-weight: bold;
                transform: translate(-50%,-50%);
                border: 2px solid #25aae1;
                color: #e53154;

            }
        }
    }


    .addresslist{
        border: 0px;
        padding: 0;
        option{
            padding: 5px 10px;


        }


        &:focus{
            outline: none;
            box-shadow: none;

        }
    }


    .section-description{
        ul{
            list-style-image: url('/image/circle1.svg');
            padding-left:20px;
            li{
                margin-bottom: 15px;
            }
        }
    }

    .blue-box{
        background: $lightblue;
        padding: 20px 60px;
        color: #fff;
        border-radius: 30px;
        margin-top: 60px;
        font-weight: 300;
        b, strong{
            font-weight: 700;
        }
        @media (max-width: 991px) {
            padding: 20px 40px;
        }
        @media (max-width: 767px) {
            padding: 20px 20px;
        }
    }

    .flow-diagram{
        margin: 0 -15px;
        flex-wrap: wrap;
        .flow{
            flex-basis: 25%;
            max-width: 25%;
            padding:  0 15px;
            margin-bottom: 15px;
            @media (max-width: 767px) {
                flex-basis: 50%;
                max-width: 50%;
            }
            @media (max-width: 575px) {
                flex-basis: 100%;
                max-width: 100%;
            }
            .inner{
                background: $lightblue;
                padding: 50px 30px;
                flex-basis: 100%;
                border-radius: 20px;
                font-size: 16px;
                font-weight: bold;
                color: #fff;
                position: relative;
                @media (max-width: 1199px) {
                    padding: 30px 20px;
                }
                @media (max-width: 991px) {
                    padding: 20px 20px;
                    font-size: 15px;
                    line-height: 1.3;
                }
                &:before{
                    content: '';
                    background: url('/image/red_arrow.png');
                    width: 36px;
                    height: 60px;
                    background-size: 36px 60px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(100%, -50%);
                    z-index: 10;
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }

            &:last-child{
                .inner{
                    &:before{
                        display: none;
                    }
                }
            }
        }
    }

    .tick-list{
        li{
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            img{
                flex-basis: 50px;
                max-width: 50px;
                height: 52px;
                margin-right: 20px;
                @media (max-width: 575px) {
                    flex-basis: 40px;
                    max-width: 40px;
                    height: 40px;
                    width: 40px;
                    margin-right: 10px;
                }
            }
        }
    }

    .numbered-list{
        li{
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            .number{
                flex-basis: 50px;
                max-width: 50px;
                height: 50px;
                width: 50px;
                border-radius: 50%;;
                margin-right: 20px;
                background: $red;
                color: #fff;
                font-size: 30px;
                font-weight: bold;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: 575px) {
                    flex-basis: 40px;
                    max-width: 40px;
                    height: 40px;
                    width: 40px;
                    margin-right: 10px;
                    font-size: 20px;
                }
            }
        }
    }

}
